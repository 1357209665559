@media (max-width: 414px) {
.landing-section div {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  width: 75%;
}

.landing-header {
  background-color: rgb(194, 194, 194);
  color: rgb(59, 59, 59);
  margin: 4%;
  padding: 2%;
}

.landing-section h3 {
  background-color: rgb(63, 63, 63);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
}


.landing-section {
  background-color: rgb(226, 226, 226);
  padding: 4%;
}

.landing-section:nth-child(even) {
  background-color: rgb(194, 194, 194);
}

.character-creation-btn {
  background-color: white;
  border: 1px solid white;
  font-size: 20px;
  width: 200px;
  height: 35px;
}

.character-creation-btn:hover {
  background-color: rgb(182, 182, 182);
  border: 1px solid rgb(182, 182, 182);
  cursor: pointer;
}
}
@media (max-width: 414px) {
.nav-bar {
  position: absolute;
  top: 101px;
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: black;
}

.nav-btn {
  background-color: rgb(90, 90, 90);
  border: 1px solid rgb(90, 90, 90);
  border-radius: 3px 15px 0px 0px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  width: 24%;
  height: 50px;
}

.nav-btn:focus {
  outline: 0;
}

.active-tab {
  background-color: rgb(163, 163, 163);
  border: 1px solid rgb(163, 163, 163);
  color: black;
}

.complete {
  color: green;
}

.nav-link:nth-child(even) {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.character-preview-nav {
  position: fixed;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 50px;
  top: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.character-preview-btn {
  background-color: rgb(204, 204, 204);
  border: 1px solid rgb(187, 187, 187);
  border-radius: 0px 0px 15px 15px;
  cursor: pointer;
  font-size: 18px;
  width: 65%;
  height: 50px;
}

.character-preview-btn:focus {
  background-color: rgb(196, 196, 196);
}

.indicator {
  display: block;
  visibility: hidden;
  pointer-events: none;
  border: 1px solid black;
  border-radius: 10px 10px 10px 10px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 0px;
  width: 50%;
  height: 2px;
}

.indicator-complete {
  display: block;
  pointer-events: none;
  border: 1px solid black;
  border-radius: 10px 10px 10px 10px;
  background-color: rgb(16, 197, 0);
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 0px;
  width: 50%;
  height: 2px;
}
}
@media (max-width: 414px) {
.selection {
  background-color: rgb(221, 221, 221);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px 5px 5px 5px;
  -moz-text-align-last: center;
       text-align-last: center;
  outline: 0;
  font-size: 20px;
  margin-top: 5%;
  width: 84%;
  height: 30px;
}

.step-section {
  /* background-color: rgb(239, 229, 213); */
  background-color: rgb(163, 163, 163);
  position: absolute;
  padding-bottom: 5%;
  width: 100%;
  top: 150px;
}

.snippet {
  background-color: white;
  border: 1px solid white;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2%;
  padding: 2%;
  width: 80%;
}

.snippet h3 {
  background-color: rgb(194, 194, 194);
  padding-top: 1%;
  padding-bottom: 1%;
}

.extra-choice-snippet {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.extra-choice-snippet h3 {
  background-color: white;
}

}
@media (max-width: 414px) {
.character-preview {
  position: absolute;
  background-color: rgb(145, 145, 145);
  width: 100%;
  top: 150px;
}

.character-preview-title {
  background-color: rgb(90, 90, 90);
  color: white;
  padding: 3%;
  margin-top: 0px;
}

.block {
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.block h3 {
  background-color: white;
  border-radius: 30px 30px 30px 30px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.stat-item {
  background-color: rgb(211, 211, 211);
  border-radius: 13px;
  float: left;
  margin: 1%;
  width: 31%;
}

.stat-name {
  background-color: white;
  border-radius: 13px 13px 0px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 4%;
  padding-bottom: 6%;
}

.stat-value {
  background-color: white;
  border: 1px solid rgb(100, 100, 100);
  border-radius: 50% 50% 50% 50%;
  font-size: 22px;
  line-height: 75px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
  width: 75px;
  height: 75px;
}

.stat-modifier {
  background-color: white;
  border: 1px solid rgb(100, 100, 100);
  border-radius: 50% 50% 50% 50%;
  line-height: 35px;
  margin-top: -20px;
  width: 35px;
  height: 35px;
}

.stat-item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 4px;
}

.randomize-stats-btn {
  background-color: white;
  border: 1px solid white;
  font-size: 20px;
  margin-top: 4%;
  width: 150px;
  height: 35px;
}

.randomize-stats-btn:focus {
  background-color: rgb(212, 212, 212);
  cursor: pointer;
}

.skill-name {
  margin-left: 3%;
}

.skill-modifier {
  border: 1px solid black;
  line-height: 25px;
  width: 25px;
  height: 25px;
  margin-left: auto;
}

.proficient {
  height: 15px;
  width: 15px;
  background-color: #555;
}

.not-proficient {
  height: 15px;
  width: 15px;
  background-color: rgb(207, 207, 207);
}

.row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 3%;
}

.long-item {
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5%;
  padding-right: 5%;
  width: 65%;
}

.hp-item {
  background-color: rgb(211, 211, 211);
  border-radius: 13px 13px 13px 13px;
  width: 25%;
}

.hp-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  padding: 5%;
  margin-bottom: 0px;
}

.initiative-item {
  background-color: rgb(211, 211, 211);
  border-radius: 13px 13px 13px 13px;
  width: 25%;
}

.initiative-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  padding: 5%;
  margin-bottom: 0px;
}

.speed-item {
  background-color: rgb(211, 211, 211);
  border-radius: 13px 13px 13px 13px;
  width: 25%;
}

.speed-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  padding: 5%;
  margin-bottom: 0px;
}
}
@media (max-width: 414px) {
@media only screen and (max-width: 414px) {
  body {
    background-color: rgb(126, 0, 0);
    text-align: center;
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html, .App, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Montserrat', sans-serif;
  }
  
  .character-creation-view {
    position: relative;
    background-color: rgb(126, 0, 0);
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    height: 100%;
  }
  
  .character-creation-view h1 {
    color: white;
    margin-top: 0px;
    padding-top: 3%;
  }
}

}
@media (min-width: 415px) {
.landing-section div {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  width: 75%;
}

.landing-header {
  background-color: rgba(58, 58, 58, 0.877);
  color: white;
  margin: 4%;
  padding: 2%;
}

.landing-section h3 {
  background-color: rgb(63, 63, 63);
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
}


.landing-section {
  background-color: rgb(226, 226, 226);
  padding: 4%;
  margin-bottom: 4%;
}

.landing-section:nth-child(even) {
  background-color: rgb(194, 194, 194);
}

.character-creation-btn {
  background-color: white;
  border: 1px solid white;
  font-size: 20px;
  width: 200px;
  height: 35px;
}

.character-creation-btn:hover {
  background-color: rgb(248, 248, 248);
  border: 1px solid rgb(248, 248, 248);
  cursor: pointer;
}
}
@media (min-width: 415px) {
.nav-bar {
  position: absolute;
  top: 100px;
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: black;
}

.nav-btn {
  background-color: rgb(90, 90, 90, 0.829);
  border: 0px solid rgb(90, 90, 90, 0.829);
  border-radius: 3px 15px 0px 0px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  width: 24%;
  height: 50px;
}

.nav-btn:focus {
  outline: 0;
}

.active-tab {
  background-color: rgb(163, 163, 163, 0.829);
  border: 0px solid rgb(163, 163, 163);
  color: black;
  font-weight: 700;
}

.complete {
  color: green;
}

.nav-link:nth-child(even) {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.character-preview-nav {
  position: fixed;
  width: 100%;
  height: 50px;
  top: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.character-preview-btn {
  background-color: rgb(204, 204, 204);
  border: 1px solid rgb(187, 187, 187);
  border-radius: 0px 0px 15px 15px;
  cursor: pointer;
  font-size: 20px;
  width: 20%;
  height: 50px;
}

.character-preview-btn:focus {
  background-color: rgb(196, 196, 196);
}

.indicator {
  display: block;
  visibility: hidden;
  pointer-events: none;
  border: 1px solid black;
  border-radius: 10px 10px 10px 10px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 0px;
  width: 50%;
  height: 2px;
}

.indicator-complete {
  display: block;
  pointer-events: none;
  border: 1px solid black;
  border-radius: 10px 10px 10px 10px;
  background-color: rgb(16, 197, 0);
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 0px;
  width: 50%;
  height: 2px;
}
}
@media (min-width: 415px) {
.selection {
  background-color: rgb(221, 221, 221);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px 5px 5px 5px;
  -moz-text-align-last: center;
       text-align-last: center;
  outline: 0;
  font-size: 20px;
  margin-top: 5%;
  width: 84%;
  height: 30px;
}

/* Size changes when snippet is loaded. Look into it */
.step-section {
  /* background-color: rgb(239, 229, 213); */
  background-color: rgba(163, 163, 163, 0.829);
  position: absolute;
  padding-bottom: 5%;
  width: 100%;
  top: 150px;
}

.snippet {
  background-color: white;
  border: 1px solid white;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2%;
  padding: 2%;
  width: 80%;
}

.snippet h3 {
  background-color: rgb(194, 194, 194);
  padding-top: 1%;
  padding-bottom: 1%;
}

.extra-choice-snippet {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.extra-choice-snippet h3 {
  background-color: white;
}

}
@media (min-width: 415px) {
.character-preview {
  position: absolute;
  width: 100%;
  top: 150px;
}

.character-preview-title {
  background-color: rgb(90, 90, 90, 0.829);
  color: white;
  padding: 3%;
  margin-top: 0px;
}

.block {
  background-color: rgba(0, 0, 0, 0.658);
  border-radius: 30px 30px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%;
  width: 90%;
}

.block h3 {
  background-color: rgb(104, 104, 104);
  border-radius: 30px 30px 0px 0px;
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100%;
}

.block h4 {
  color: white;
}

.stat-row {
  margin-right: auto;
  margin-left: auto;
  width: 85%;
}

.stat-item {
  background-color: rgb(194, 89, 20);
  border-radius: 13px;
  float: left;
  margin: 1%;
  width: 31%;
}

.stat-name {
  background-color: white;
  border-radius: 13px 13px 0px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 4%;
  padding-bottom: 6%;
}

.stat-value {
  background-color: white;
  border-radius: 50% 50% 50% 50%;
  font-size: 22px;
  line-height: 75px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
  width: 75px;
  height: 75px;
}

.stat-modifier {
  background-color: white;
  border: 2px solid rgb(194, 89, 20);
  border-radius: 50% 50% 50% 50%;
  line-height: 35px;
  margin-top: -20px;
  width: 35px;
  height: 35px;
}

.stat-item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 4px;
}

.randomize-stats-btn {
  background-color: white;
  border: 1px solid white;
  font-size: 20px;
  margin-top: 4%;
  width: 150px;
  height: 35px;
}

.randomize-stats-btn:focus {
  background-color: rgb(212, 212, 212);
  cursor: pointer;
}

.skill-name {
  margin-left: 3%;
}

.skill-modifier {
  border-radius: 0px 15px 0px 15px;
  background-color: rgb(194, 89, 20);
  color: white;
  line-height: 35px;
  width: 35px;
  height: 35px;
  margin-left: auto;
}

.proficient {
  border-radius: 15px;
  height: 15px;
  width: 15px;
  background-color: #555;
}

.not-proficient {
  border-radius: 15px;
  height: 15px;
  width: 15px;
  background-color: rgb(226, 226, 226);
}

.row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3%;
  width: 85%;
}

.long-item {
  background-color: white;
  border-radius: 0px 50px 0px 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5%;
  padding-right: 5%;
  width: 65%;
}

.long-item h4 {
  color: rgb(44, 44, 44);
}

.hp-item {
  background-color: rgb(194, 89, 20);
  border-radius: 13px 13px 13px 13px;
  color: white;
  width: 25%;
}

.hp-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  color: rgb(44, 44, 44);
  padding: 5%;
  margin-bottom: 0px;
}

.initiative-item {
  background-color: rgb(194, 89, 20);
  border-radius: 13px 13px 13px 13px;
  color: white;
  width: 25%;
}

.initiative-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  color: rgb(44, 44, 44);
  padding: 5%;
  margin-bottom: 0px;
}

.speed-item {
  background-color: rgb(194, 89, 20);
  border-radius: 13px 13px 13px 13px;
  color: white;
  width: 25%;
}

.speed-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  color: rgb(44, 44, 44);
  padding: 5%;
  margin-bottom: 0px;
}
}
@media (min-width: 415px) {
@media only screen and (min-width: 415px) {
  body {
    background-color: rgb(126, 0, 0);
    background-image: url('http://getwallpapers.com/wallpaper/full/3/f/1/59952.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html, body, .App, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Montserrat', sans-serif;
  }
  
  .landing {
    width: 65%;
    margin-right: auto;
    margin-left: auto;
  }

  .character-creation-view {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    width: 65%;
    height: 100%;
  }
  
  .character-creation-view h1 {
    color: white;
    margin-top: 0px;
    padding-top: 3%;
  }
}

}

