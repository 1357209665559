@import './landing-mobile-style.css';
@import './navigation-mobile-style.css';
@import './section-mobile-style.css';
@import './character-preview-mobile-style.css';
@media only screen and (max-width: 414px) {
  body {
    background-color: rgb(126, 0, 0);
    text-align: center;
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html, .App, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Montserrat', sans-serif;
  }
  
  .character-creation-view {
    position: relative;
    background-color: rgb(126, 0, 0);
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    height: 100%;
  }
  
  .character-creation-view h1 {
    color: white;
    margin-top: 0px;
    padding-top: 3%;
  }
}
