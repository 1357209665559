@import './landing-desktop-style.css';
@import './navigation-desktop-style.css';
@import './section-desktop-style.css';
@import './character-preview-desktop-style.css';
@media only screen and (min-width: 415px) {
  body {
    background-color: rgb(126, 0, 0);
    background-image: url('http://getwallpapers.com/wallpaper/full/3/f/1/59952.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html, body, .App, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Montserrat', sans-serif;
  }
  
  .landing {
    width: 65%;
    margin-right: auto;
    margin-left: auto;
  }

  .character-creation-view {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    width: 65%;
    height: 100%;
  }
  
  .character-creation-view h1 {
    color: white;
    margin-top: 0px;
    padding-top: 3%;
  }
}
