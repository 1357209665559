.character-preview {
  position: absolute;
  width: 100%;
  top: 150px;
}

.character-preview-title {
  background-color: rgb(90, 90, 90, 0.829);
  color: white;
  padding: 3%;
  margin-top: 0px;
}

.block {
  background-color: rgba(0, 0, 0, 0.658);
  border-radius: 30px 30px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%;
  width: 90%;
}

.block h3 {
  background-color: rgb(104, 104, 104);
  border-radius: 30px 30px 0px 0px;
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100%;
}

.block h4 {
  color: white;
}

.stat-row {
  margin-right: auto;
  margin-left: auto;
  width: 85%;
}

.stat-item {
  background-color: rgb(194, 89, 20);
  border-radius: 13px;
  float: left;
  margin: 1%;
  width: 31%;
}

.stat-name {
  background-color: white;
  border-radius: 13px 13px 0px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 4%;
  padding-bottom: 6%;
}

.stat-value {
  background-color: white;
  border-radius: 50% 50% 50% 50%;
  font-size: 22px;
  line-height: 75px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
  width: 75px;
  height: 75px;
}

.stat-modifier {
  background-color: white;
  border: 2px solid rgb(194, 89, 20);
  border-radius: 50% 50% 50% 50%;
  line-height: 35px;
  margin-top: -20px;
  width: 35px;
  height: 35px;
}

.stat-item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 4px;
}

.randomize-stats-btn {
  background-color: white;
  border: 1px solid white;
  font-size: 20px;
  margin-top: 4%;
  width: 150px;
  height: 35px;
}

.randomize-stats-btn:focus {
  background-color: rgb(212, 212, 212);
  cursor: pointer;
}

.skill-name {
  margin-left: 3%;
}

.skill-modifier {
  border-radius: 0px 15px 0px 15px;
  background-color: rgb(194, 89, 20);
  color: white;
  line-height: 35px;
  width: 35px;
  height: 35px;
  margin-left: auto;
}

.proficient {
  border-radius: 15px;
  height: 15px;
  width: 15px;
  background-color: #555;
}

.not-proficient {
  border-radius: 15px;
  height: 15px;
  width: 15px;
  background-color: rgb(226, 226, 226);
}

.row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3%;
  width: 85%;
}

.long-item {
  background-color: white;
  border-radius: 0px 50px 0px 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 5%;
  padding-right: 5%;
  width: 65%;
}

.long-item h4 {
  color: rgb(44, 44, 44);
}

.hp-item {
  background-color: rgb(194, 89, 20);
  border-radius: 13px 13px 13px 13px;
  color: white;
  width: 25%;
}

.hp-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  color: rgb(44, 44, 44);
  padding: 5%;
  margin-bottom: 0px;
}

.initiative-item {
  background-color: rgb(194, 89, 20);
  border-radius: 13px 13px 13px 13px;
  color: white;
  width: 25%;
}

.initiative-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  color: rgb(44, 44, 44);
  padding: 5%;
  margin-bottom: 0px;
}

.speed-item {
  background-color: rgb(194, 89, 20);
  border-radius: 13px 13px 13px 13px;
  color: white;
  width: 25%;
}

.speed-title {
  background-color: white;
  border-radius: 0px 0px 13px 13px;
  color: rgb(44, 44, 44);
  padding: 5%;
  margin-bottom: 0px;
}